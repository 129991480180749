<div
  class="app-frame">
<mat-card
  class="app-box app-col-1">
<mat-card-title>
Update
</mat-card-title>
<mat-card-subtitle>
Updated site content
</mat-card-subtitle>
</mat-card>
<mat-card
  class="app-box app-col-2">
<mat-card-content>
<h1>
2020 Oct 31 Sat
</h1>
<ul>
<li>
The <a routerLink="/profile">
profile</a>
page showing your user data is now working.
</li>
</ul>
<h1>
2020 Oct 04 Sun
</h1>
<ul>
<li>
The <a routerLink="/login">
login</a>
page is now working and ready for testing.
You can create a new test user and log in but there is nothing you can do once
logged in and the test user that you create will eventually be deleted.
</li>
</ul>
<h1>
2020 Aug 09 Sun
</h1>
<ul>
<li>
The <a routerLink="/public-domain">Public Domain</a> page has more information
about the sources.
</li>
</ul>
<h1>
2020 Jul 19 Sun
</h1>
<ul>
<li>
We added a <a routerLink="/privacy-policy">Privacy Policy</a> page
</li>
</ul>
<h1>
2020 May 31 Sun
</h1>
<ul>
<li>
We started a <a routerLink="/blogs">Blogs</a> section
</li>
</ul>
<h1>
2020 May 10 Sun
</h1>
<ul>
<li>
Complete rewrite of website started
</li>
<li>
We now have a page of links to
<a routerLink="/public-domain">Public Domain</a>
media
</li>
</ul>
</mat-card-content>
</mat-card>
<mat-card
  class="app-box app-col-3">
<mat-card-title>
Site Links
</mat-card-title>
<mat-card-content>
<nav>
<ul>
<li>
<p>
<a routerLink="/blogs">
Blogs</a>
</p>
</li>
<li>
<p>
<a routerLink="/public-domain">
Public Domain</a>
</p>
</li>
</ul>
</nav>
</mat-card-content>
</mat-card>
</div>